export const CATEGORY_X = 22;
export const CATEGORY_Y = 23;
export const CATEGORY_UNKNOWN = 24;
export const CATEGORY_IDS = [...new Array(25)].map((_, index) => index);
export const CATEGORY_LABELS = CATEGORY_IDS.map((_, index) => {
  switch(index) {
    case CATEGORY_X: return 'X';
    case CATEGORY_Y: return 'Y';
    case CATEGORY_UNKNOWN: return '?';
    default: return `${index + 1}`;
  }
});
export const KARYOGRAM_LINE_BREAK = [4, 11, 17];
