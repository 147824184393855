import { ChromosomeData } from '../Karyogram/types';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { bindContextMenu, bindMenu } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { useEffect, useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { DraggableSyntheticListeners, UniqueIdentifier } from '@dnd-kit/core';
import type {Transform} from '@dnd-kit/utilities';
import React from 'react';
import classNames from 'classnames';

import styles from './ChromosomeCard.module.scss';
import CircularProgress from '@mui/material/CircularProgress';
import Badge from '@mui/material/Badge';
import { AuthorizedImage } from '../AuthorizedImage';
import { calcRotatedSize } from '../../utils/utils';

export type ChromosomeImageProps = {
  [prop: string]: any;
}

export interface ChromosomeCardProps {
  chromosome: ChromosomeData;
  menuCallbacks: ChromosomeMenuCallback[];
  imageProps?: ChromosomeImageProps;
  token?: string;
  children?: any;
}

export interface ChromosomeMenuCallback {
  label: string;
  icon: any;
  callback: (chromosome: ChromosomeData) => any;
}

interface SortableChromosomeCardProps {
  containerId: UniqueIdentifier;
  index: number;
  handle: boolean;
  disabled?: boolean;
  style(args: any): React.CSSProperties;
  getIndex(id: UniqueIdentifier): number;
  wrapperStyle({index}: {index: number}): React.CSSProperties;
  chromosome: ChromosomeData;
  chromosomeMenuCallbacks: ChromosomeMenuCallback[];
  chromosomeImageProps?: ChromosomeImageProps;
  forImageExport?: boolean;
  token?: string;
}

export function SortableChromosomeCard({
  token,
  disabled,
  index,
  handle,
  style,
  containerId,
  getIndex,
  wrapperStyle,
  chromosome,
  chromosomeMenuCallbacks,
  chromosomeImageProps,
  forImageExport,
}: SortableChromosomeCardProps) {
  const {
    setNodeRef,
    setActivatorNodeRef,
    listeners,
    isDragging,
    isSorting,
    over,
    overIndex,
    transform,
    transition,
  } = useSortable({
    id: chromosome.key,
  });
  const mounted = useMountStatus();
  const mountedWhileDragging = isDragging && !mounted;

  const popupState = usePopupState({ variant: 'popover', popupId: `${chromosome.key}-menu` });
  const [loading, setLoading] = useState(false);

  const buildHandleClick = (callback: (c: ChromosomeData) => Promise<any>) => {
    return () => {
      setLoading(true);
      callback(chromosome).then((data: any) => {
        setLoading(false);
        return data;
      });
      popupState.close();
    }
  }

  const { width: minWidth } = calcRotatedSize(chromosome.height, chromosome.width, chromosome.rotation ?? 0);

  return (
    <Badge
      badgeContent={'!'}
      anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      color="warning"
      invisible={!chromosome.edited}
      sx={{'& .MuiBadge-badge': {visibility: forImageExport ? 'hidden' : 'visible'}}}
    >
      <ChromosomeItem
        ref={disabled ? undefined : setNodeRef}
        value={<>
          <AuthorizedImage
            key={chromosome.key}
            token={token}
            url={chromosome.imageUrl}
            alt={String(chromosome.key)}
            revoke={false}
            data-uuid={chromosome.key}
            style={{ transform: `rotate(${-(chromosome.rotation ?? 0)}deg) ${chromosome.verticalFlip ? 'scaleX(-1)' : ''}` }}
          />
          {loading ? <CircularProgress
            size={24}
            sx={{
              //color: green[500],
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          /> : null}
        </>}
        dragging={isDragging}
        sorting={isSorting}
        handle={handle}
        handleProps={handle ? {ref: setActivatorNodeRef} : undefined}
        index={index}
        wrapperStyle={{
          ...wrapperStyle({index}),
          ...(chromosome.width && {minWidth})
        }}
        style={{
          ...style({
            index,
            value: chromosome.key,
            isDragging,
            isSorting,
            overIndex: over ? getIndex(over.id) : overIndex,
            containerId,
          }),
          justifyContent: 'center',
        }}
        transition={transition}
        transform={transform}
        fadeIn={mountedWhileDragging}
        listeners={listeners}
        {...(chromosomeMenuCallbacks.length && bindContextMenu(popupState))}
        {...chromosomeImageProps}
        data-uuid={chromosome.key}
      />
      {
        chromosomeMenuCallbacks.length
        ? <Menu {...bindMenu(popupState)} anchorReference="anchorEl">
            {chromosomeMenuCallbacks.map((cb, i) => (
              <MenuItem onClick={buildHandleClick(cb.callback)} key={i}>
                <ListItemIcon>
                  {cb.icon}
                </ListItemIcon>
                {cb.label}
              </MenuItem>
            ))}
          </Menu>
        : null
      }
    </Badge>
  );
}

function useMountStatus() {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), 500);

    return () => clearTimeout(timeout);
  }, []);

  return isMounted;
}

export interface ChromosomeItemProps {
  dragOverlay?: boolean;
  color?: string;
  disabled?: boolean;
  dragging?: boolean;
  handle?: boolean;
  handleProps?: any;
  height?: number;
  index?: number;
  fadeIn?: boolean;
  transform?: Transform | null;
  listeners?: DraggableSyntheticListeners;
  sorting?: boolean;
  style?: React.CSSProperties;
  transition?: string | null;
  wrapperStyle?: React.CSSProperties;
  value: React.ReactNode;
  onRemove?(): void;
}

export const ChromosomeItem = React.memo(
  React.forwardRef<HTMLLIElement, ChromosomeItemProps>(
    (
      {
        color,
        dragOverlay,
        dragging,
        disabled,
        fadeIn,
        handle,
        handleProps,
        height,
        index,
        listeners,
        onRemove,
        sorting,
        style,
        transition,
        transform,
        value,
        wrapperStyle,
        ...props
      },
      ref
    ) => {
      useEffect(() => {
        if (!dragOverlay) {
          return;
        }

        document.body.style.cursor = 'grabbing';

        return () => {
          document.body.style.cursor = '';
        };
      }, [dragOverlay]);

      return (
        <li
          className={classNames(
            styles.Wrapper,
            fadeIn && styles.fadeIn,
            sorting && styles.sorting,
            dragOverlay && styles.dragOverlay
          )}
          style={
            {
              ...wrapperStyle,
              transition: [transition, wrapperStyle?.transition]
                .filter(Boolean)
                .join(', '),
              '--translate-x': transform
                ? `${Math.round(transform.x)}px`
                : undefined,
              '--translate-y': transform
                ? `${Math.round(transform.y)}px`
                : undefined,
              '--scale-x': transform?.scaleX
                ? `${transform.scaleX}`
                : undefined,
              '--scale-y': transform?.scaleY
                ? `${transform.scaleY}`
                : undefined,
              '--index': index,
              '--color': color,
            } as React.CSSProperties
          }
          ref={ref}
        >
          <div
            className={classNames(
              styles.ChromosomeItem,
              dragging && styles.dragging,
              handle && styles.withHandle,
              dragOverlay && styles.dragOverlay,
              disabled && styles.disabled,
              color && styles.color
            )}
            style={style}
            data-cypress="draggable-item"
            {...(!handle ? listeners : undefined)}
            {...props}
            tabIndex={!handle ? 0 : undefined}
          >
            {value}
          </div>
        </li>
      );
    }
  )
);
