import Box from "@mui/material/Box";
import { AnnotationDto, PointDto, PredictionsDto, UUID } from "../data/service";
import * as d3 from 'd3';
import { useEffect, useRef, useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { bindMenu } from "material-ui-popup-state";
import { bindContextMenu, usePopupState } from "material-ui-popup-state/hooks";
import DeleteOutlined from "@mui/icons-material/DeleteOutlined";
import JoinFullOutlined from "@mui/icons-material/JoinFullOutlined";
import ContentCutOutlined from "@mui/icons-material/ContentCutOutlined";
import ExtendIcon from '@mui/icons-material/FiberSmartRecord';
import DrawIcon from '@mui/icons-material/Gesture';
import classNames from "classnames";
import { AuthorizedSvgImage } from "../core-components/AuthorizedImage";

export enum EditTool {
  Zoom = 'zoom',
  Clip = 'clip',
  Merge = 'merge',
  Delete = 'delete',
  Extend = 'extend',
  FreeSelect = 'freeSelect',
}

export type ChromosomeOpCallback = (r: EditToolResult) => Promise<any>;

export interface EditToolResult {
  transform: string | null;
  uuids: UUID[];
  path?: PointDto[];
}

export interface ChromosomeOps {
  merge: ChromosomeOpCallback;
  separate: ChromosomeOpCallback;
  delete: ChromosomeOpCallback;
  extend: ChromosomeOpCallback;
  freeSelect: ChromosomeOpCallback;
}

interface LocalMenuItem {
  icon: JSX.Element;
  label: string;
  callback: () => void;
}

export interface SegmentationEditorProps {
  token: string;
  image: string;
  predictions: PredictionsDto;
  transform: string | null;
  chromosomeOps: ChromosomeOps;
}

export default function SegmentationPlot({
  token,
  image,
  predictions,
  transform,
  chromosomeOps,
}: SegmentationEditorProps) {
  const [selectedChromo, setSelectedChromo] = useState<UUID | null>();
  const [currentTool, setCurrentTool] = useState<EditTool>(EditTool.Zoom);
  const popupState = usePopupState({ variant: 'popover', popupId: `background-menu` });
  const svgRef = useRef();

  useEffect(() => {
    if (!svgRef.current) {
      return;
    }

    const svg = d3.select(svgRef.current).selectChild('svg');

    const resetState = () => {
      setSelectedChromo(null);
      setCurrentTool(EditTool.Zoom);
    }

    const selectZoomTool = () => {
      const handleZoom = (e: any) => {
        svg.select('g').attr('transform', e.transform);
        svg.select('image').attr('transform', e.transform);
      }

      let zoom = d3.zoom<any, unknown>()
        .translateExtent([[0, 0], [predictions.width, predictions.height]])
        .on('zoom', handleZoom);

      svg.call(zoom).on('dblclick.zoom', null);
    }

    const deselectZoomTool = () => {
      svg.on('.zoom', null);
    }

    const selectMergeTool = (callback: (r: EditToolResult) => void) => {
      svg.selectAll('.chromosome')
        .on('click', (ev) => {
          callback({
            uuids: [selectedChromo, ev.target.dataset.uuid],
            transform: svg.select('g').attr('transform'),
          });
          deselectMergeTool();
        });
    }

    const deselectMergeTool = () => {
      svg.selectAll('.chromosome')
        .on('click', null);
      resetState();
    }

    const selectClipTool = (callback: (r: EditToolResult) => void) => {
      deselectZoomTool();
      let state: 'selected' | 'drawing' = 'selected';
      let points: PointDto[] = [];

      const drawPoint = (event: any) => {
        if (state !== 'drawing') {
          return;
        }

        const transformRef = svg.selectChild('g').node();
        const coord = d3.pointer(event, transformRef);

        points.push(coord);
        if (points.length === 2) {
          svg.selectChild('g').append('path')
            .attr('d', d3.line()(points))
            .attr('vector-effect', 'non-scaling-stroke')
            .attr('class', 'separate pathSegment');
        } else if (points.length > 2) {
          svg.selectChild('g').selectChild('path.separate.pathSegment')
            .attr('d', d3.line()(points));
        } else {
          const [px, py] = points[0];
          svg.selectChild('g').append('path')
            .attr('d', d3.line()([[px-1, py-1], [px+1, py+1]]))
            .attr('vector-effect', 'non-scaling-stroke')
            .attr('class', 'separate pathStartMarker');
          svg.selectChild('g').append('path')
            .attr('d', d3.line()([[px-1, py+1], [px+1, py-1]]))
            .attr('vector-effect', 'non-scaling-stroke')
            .attr('class', 'separate pathStartMarker');
        }
      }

      svg.on('mousemove', drawPoint)
        .on('mousedown', () => {
          if (state === 'selected') {
            state = 'drawing';
          }
        })
        .on('mouseup', () => {
          if (state !== 'drawing') {
            return;
          }
          callback({
            uuids: [selectedChromo!],
            path: points,
            transform: svg.select('g').attr('transform'),
          });
          points = [];
          deselectClipTool();
        });
    }

    const deselectClipTool = () => {
      svg
        .on('mousemove', null)
        .on('mousedown', null)
        .on('mouseup', null);
      svg.selectAll('.chromosome')
        .on('click', null);
      svg.selectAll('.separate').remove();
      resetState();
    }

    const selectDeleteTool = (callback: (r: EditToolResult) => void) => {
      callback({
        uuids: [selectedChromo!],
        transform: svg.select('g').attr('transform'),
      });
      resetState();
    }

    const selectExtendTool = (callback: (r: EditToolResult) => void) =>
      selectClipTool(callback);

    // Works on the background image, not the contour!
    const selectFreeSelectTool = (callback: (r: EditToolResult) => void) => {
      deselectZoomTool();
      let state: 'idle' | 'drawing' = 'idle';
      let points: PointDto[] = [];

      const drawPoint = (event: any) => {
        if (state !== 'drawing') {
          return;
        }

        const transformRef = svg.selectChild('g').node();
        const coord = d3.pointer(event, transformRef);

        points.push(coord);
        if (points.length === 2) {
          svg.selectChild('g').append('path')
            .attr('d', d3.line()(points))
            .attr('vector-effect', 'non-scaling-stroke')
            .attr('class', 'freehandSelect pathSegment');
        } else if (points.length > 2) {
          svg.selectChild('g').selectChild('path.freehandSelect.pathSegment')
            .attr('d', d3.line()([...points, points[0]]));
        } else {
          const [px, py] = points[0];
          svg.selectChild('g').append('path')
            .attr('d', d3.line()([[px-1, py-1], [px+1, py+1]]))
            .attr('vector-effect', 'non-scaling-stroke')
            .attr('class', 'freehandSelect pathStartMarker');
          svg.selectChild('g').append('path')
            .attr('d', d3.line()([[px-1, py+1], [px+1, py-1]]))
            .attr('vector-effect', 'non-scaling-stroke')
            .attr('class', 'freehandSelect pathStartMarker');
        }
      }

      svg.on('mousemove', drawPoint)
        .on('mousedown', () => {
          if (state === 'idle') {
            state = 'drawing';
          }
        })
        .on('mouseup', () => {
          if (state !== 'drawing') {
            return;
          }
          callback({
            uuids: [selectedChromo!],
            path: points,
            transform: svg.selectChild('g').attr('transform'),
          });
          points = [];
          deselectFreeSelectTool();
        });
    }

    const deselectFreeSelectTool = () => {
      svg
        .on('mousemove', null)
        .on('mousedown', null)
        .on('mouseup', null);
      svg.selectAll('.chromosome')
        .on('click', null);
      svg.selectAll('.freehandSelect').remove();
      resetState();
    }

    svg.attr('draggable', false)
        .on('dragstart', (event) => { event.preventDefault(); });

    switch(currentTool) {
      case EditTool.Zoom: selectZoomTool(); break;
      case EditTool.Clip: selectClipTool(chromosomeOps.separate); break;
      case EditTool.Merge: selectMergeTool(chromosomeOps.merge); break;
      case EditTool.Delete: selectDeleteTool(chromosomeOps.delete); break;
      case EditTool.Extend: selectExtendTool(chromosomeOps.extend); break;
      case EditTool.FreeSelect: selectFreeSelectTool(chromosomeOps.freeSelect); break;
      default: break;
    }
  }, [transform, predictions.width, predictions.height, currentTool, selectedChromo, chromosomeOps]);

  const colorSequence = d3.scaleSequential()
      .domain([0, 1])
      .interpolator(d3.interpolateViridis);

  const handleTool = (tool: EditTool, uuid: UUID) => {
    setCurrentTool(tool);
    setSelectedChromo(uuid);
  }

  const bgCallBacks: LocalMenuItem[] = [
    {
      icon: <DrawIcon />,
      label: 'Free select',
      callback: () => {
        setCurrentTool(EditTool.FreeSelect);
        popupState.close();
      }
    }
  ];

  return (
    <Box
      ref={svgRef}
      sx={{ width: '100%', height: '100%' }}
    >
      <svg
        id={`#segmentation-plot-editor`}
        width={'100%'/*predictions.width*/}
        height={'100%'/*predictions.height*/}
      >
        <AuthorizedSvgImage
          token={token}
          url={image}
          width={predictions.width}
          height={predictions.height}
          transform={transform ?? undefined}
          {...bindContextMenu(popupState)}
        />
        <Menu
          {...bindMenu(popupState)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
          {bgCallBacks.map((cb, i) => (
            <MenuItem onClick={cb.callback} key={i}>
              <ListItemIcon>
                {cb.icon}
              </ListItemIcon>
              {cb.label}
            </MenuItem>
          ))}
        </Menu>
        <g transform={transform ?? undefined}>
          {
            predictions.annotations
            .filter(a => a.suggestion === 'selected' || a.suggestion === 'user_edited')
            .map((d, i) => <ChromoContour
              key={d.uuid}
              i={i}
              annotation={d}
              color={colorSequence(i / predictions.annotations.length)}
              selected={d.uuid === selectedChromo}
              callTool={handleTool}
              extraMenuItems={bgCallBacks}
              hasMenu
            />)
          }
        </g>
      </svg>
    </Box>
  );
}

interface SegmentationViewerProps {
  token: string;
  image: string;
  predictions: PredictionsDto;
  transform: string | null;
  selectedChromo?: UUID;
}

export function SegmentationViewer({
  token,
  image,
  predictions,
  transform,
  selectedChromo,
}: SegmentationViewerProps) {
  const colorSequence = d3.scaleSequential()
      .domain([0, 1])
      .interpolator(d3.interpolateViridis);

  return (
    <Box>
      <svg
        id={`#segmentation-plot-viewer`}
        width="100%"
        height="100%"
        style={{
          border: 'solid black',
        }}
        viewBox={`0 0 ${predictions.width} ${predictions.height}`}
      >
        <AuthorizedSvgImage
          token={token}
          url={image}
          width={predictions.width}
          height={predictions.height}
          transform={transform ?? undefined}
        />
        <g transform={transform ?? undefined}>
          {
            predictions.annotations
            .filter(a => a.suggestion === 'selected' || a.suggestion === 'user_edited')
            .map((d, i) => <ChromoContour
              key={d.uuid}
              i={i}
              annotation={d}
              color={colorSequence(i / predictions.annotations.length)}
              selected={d.uuid === selectedChromo}
            />)
          }
        </g>
      </svg>
    </Box>
  );
}

function ChromoContour({
  i,
  annotation,
  color,
  selected,
  callTool,
  extraMenuItems = [],
  hasMenu = false,
}: {
  i: number;
  annotation: AnnotationDto;
  color: string;
  selected: boolean;
  callTool?: (tool: EditTool, uuid: UUID) => any;
  extraMenuItems?: LocalMenuItem[];
  hasMenu?: boolean;
}) {
  const popupState = usePopupState({ variant: 'popover', popupId: `contour-${i}-menu` });

  const chromosomeMenuCallbacks: LocalMenuItem[] = callTool ? [
    {
      icon: <JoinFullOutlined />,
      label: 'Merge',
      callback: () => {
        callTool(EditTool.Merge, annotation.uuid);
        popupState.close();
      },
    },
    {
      icon: <ContentCutOutlined />,
      label: 'Separate',
      callback: () => {
        callTool(EditTool.Clip, annotation.uuid);
        popupState.close();
      },
    },
    {
      icon: <ExtendIcon />,
      label: 'Extend',
      callback: () => {
        callTool(EditTool.Extend, annotation.uuid);
        popupState.close();
      },
    },
    {
      icon: <DeleteOutlined />,
      label: 'Delete',
      callback: () => {
        callTool(EditTool.Delete, annotation.uuid);
        popupState.close();
      },
    },
    ...extraMenuItems.map((item) => ({
      icon: item.icon,
      label: item.label,
      callback: () => {
        item.callback();
        popupState.close();
      },
    })),
  ] : [];

  return (
    <>
      <path
        className={classNames(
          'chromosome contour',
          selected && 'selected',
        )}
        data-uuid={annotation.uuid}
        d={d3.line()([
          ...annotation.segmentation[0],
          annotation.segmentation[0][0]
        ]) ?? undefined}
        vectorEffect="non-scaling-stroke"
        style={{
          strokeWidth: 2,
          stroke: color,
          fill: `${color}15`,
        }}
        {...hasMenu && bindContextMenu(popupState)}
      />
      { hasMenu &&
        <Menu {...bindMenu(popupState)} anchorReference="anchorEl">
          {chromosomeMenuCallbacks.map((cb, i) => (
            <MenuItem onClick={cb.callback} key={i}>
              <ListItemIcon>
                {cb.icon}
              </ListItemIcon>
              {cb.label}
            </MenuItem>
          ))}
        </Menu>
      }
    </>
  );
}