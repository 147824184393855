import { useEffect, useState } from "react";
import { CreateSampleDto, PatientDto, SampleDto, UUID, createSample, getSamples } from "../data/service";
import { useToken } from "../data/hooks";
import { useForm } from "react-hook-form";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import { FormInputText } from "./FormInputText";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CardContent from "@mui/material/CardContent";
import { DataGrid, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import CardHeader from "@mui/material/CardHeader";
import { PostAdd, SwitchAccount } from "@mui/icons-material";
import { SxProps } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import Alert from "@mui/material/Alert";

export interface SampleListProps {
  patient: PatientDto;
  selectedId?: UUID;
  onSelect?: (sample: SampleDto) => any;
  onSwitchPatient?: () => any;
  sx?: SxProps;
}

export default function SampleList({
  patient,
  selectedId,
  onSelect,
  onSwitchPatient,
  sx,
}: SampleListProps) {
  const [token, ] = useToken();
  const [reload, setReload] = useState(true);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const { handleSubmit, control, reset } = useForm<SampleDto>();

  const handleAddPatient = () => {
    setAddDialogOpen(true);
  }

  const handleAddClose = () => {
    setAddDialogOpen(false);
  }

  const onPatientSubmit = (data: CreateSampleDto) => {
    createSample(token, {...data, patient_id: patient._id})
    .then(() => setReload((r) => !r))
    .catch(console.error);
    setAddDialogOpen(false);
    reset();
  }

  return (
    <Card sx={{ p: 2, ...sx }}>
      <CardHeader
        title="Samples"
        action={
          <>
            <Button
              color="primary"
              onClick={handleAddPatient}
              aria-label="add patient"
              startIcon={<PostAdd />}
            >
              Add sample
            </Button>
            {
              onSwitchPatient
                ? <Button
                    color="secondary"
                    onClick={onSwitchPatient}
                    aria-label="switch patient"
                    startIcon={<SwitchAccount />}
                  >
                    Switch patient
                  </Button>
                : null
            }
            <Dialog open={addDialogOpen} onClose={handleAddClose}>
              <DialogTitle>Add sample</DialogTitle>
              <DialogContent>
              <DialogContentText>
                Enter sample data.
              </DialogContentText>
              <FormInputText
                control={control}
                name="sample_name"
                label="Sample name"
                required
              />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleAddClose}>Cancel</Button>
                <Button onClick={handleSubmit(onPatientSubmit)}>Submit</Button>
              </DialogActions>
            </Dialog>
          </>
        }
        sx={{ height: '5em' }}
      />
      <CardContent sx={{ height: 'calc(100% - 5em)' }}>
        <LoadSamplesTable
          patientId={patient._id}
          reload={reload}
          onSelect={onSelect}
          selectedId={selectedId}
        />
      </CardContent>
    </Card>
  );
}

export interface LoadSamplesTableProps {
  patientId: UUID;
  selectedId?: UUID;
  onSelect?: (sample: SampleDto) => any;
  reload: boolean;
}

export function LoadSamplesTable({
  patientId,
  selectedId,
  onSelect,
  reload,
}: LoadSamplesTableProps) {

  const [token, ] = useToken();
  const [samples, setSamples] = useState<SampleDto[]>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    let ignore = false;
    const load = async () => {
      try {
        const p = await getSamples(token, {patient_id: patientId});
        if (!ignore) {
          setSamples(p);
        }
      } catch (err) {
        setError(true);
      }
    }
    load();
    setLoading(false);
    return () => { ignore = true };
  }, [token, reload, patientId]);

  if (loading || !samples) {
    return <GhostSampleTable />;
  }
  if (error) {
    return <Alert severity="error">Could&apos;t load sample data.</Alert>;
  }
  return (
    <SampleTable
      samples={samples}
      selectedId={selectedId}
      onSelect={onSelect}
    />
  );
}

export interface SampleTableProps {
  samples: SampleDto[];
  selectedId?: UUID;
  onSelect?: (sample: SampleDto) => any;
}

export function SampleTable({
  samples,
  selectedId,
  onSelect,
}: SampleTableProps) {

  const columns: GridColDef[] = [
    { field: 'sample_name', headerName: 'Sample name' },
  ];

  const handleSelect = (selection : GridRowSelectionModel) => {
    if (onSelect) {
      const selectedObj = samples.filter((s: SampleDto) => s._id === selection[0])![0];
      if (selectedObj) {
        onSelect(selectedObj);
      }
    }
  }

  return (
    samples.length
    ? <DataGrid
        rows={samples}
        getRowId={(row) => row._id}
        columns={columns}
        rowSelectionModel={selectedId ? [selectedId] : undefined}
        onRowSelectionModelChange={handleSelect}
      />
    : <Alert severity="info">No samples are registered yet for this patient.</Alert>
  );
}

export function GhostSampleTable() {
  return (
    <Grid container spacing={1}>
      {
        [1, 2].map((e) => <Grid item xs={12} key={e}>
          <Skeleton variant="rectangular" height="2em" />
        </Grid>)
      }
    </Grid>
  );
}
