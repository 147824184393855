import { Navigate, useLocation } from "react-router-dom";
import { useToken } from "../data/hooks";

export const ProtectedRoute = ({ children } : { children: any }) => {
  const [token, ] = useToken();
  const location = useLocation();
  if (!token) {
    // user is not authenticated
    return <Navigate to="/" state={{ from: location }} />;
  }
  return children;
};
