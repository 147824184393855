import { useEffect, useRef, useState } from "react";
import { useToken } from "../data/hooks";
import {
  ListThumbnailsDto,
  SampleDto,
  UUID,
  getSample,
  listThumbnails,
  uploadImage,
} from "../data/service";
import AddPhotoAlternateOutlined from "@mui/icons-material/AddPhotoAlternateOutlined";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import LinearProgress from "@mui/material/LinearProgress";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import { SxProps } from "@mui/material/styles";
import ClosableAlert from "../core-components/ClosableAlert";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import UploadGallery from "../components/UploadGallery";
import CompactGallery from "../components/CompactGallery";
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { timestampToString } from "../utils/utils";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

const THUMBNAIL_SIZE = 300;

export interface SampleDetailsPageProps {
  onSelect?: (uploadId: UUID) => any;
  sx?: SxProps;
}

interface BadFile {
  name: string;
  message: string;
}

type ViewMode = 'list' | 'grid';

/**
 * * User interface for selecting view mode (list, compact, etc.)
 * * File upload
 * * Fetch sample content
 */
export default function SampleDetailsPage({
  onSelect,
  sx,
}: SampleDetailsPageProps) {
  const navigate = useNavigate();
  const { sampleId } = useParams();  // TODO handle if not given
  const [searchParams, setSearchParams] = useSearchParams();

  const [viewMode, setViewMode] = useState<ViewMode | null>(searchParams.get('mv') as ViewMode || 'grid');

  const [token, ] = useToken();
  const [loading, setLoading] = useState(true);
  const [uploadedData, setUploadedData] = useState<ListThumbnailsDto[]>();
  const [files, setFiles] = useState<FileList | null>(null);
  const [uploadedCount, setUploadedCount] = useState<number>(0);
  const [uploading, setUploading] = useState(false);
  const [badFiles, setBadFiles] = useState<BadFile[]>([]);
  const fileInput = useRef<HTMLInputElement | null>();
  const [sample, setSample] = useState<SampleDto>();

  const handleUploadId = (id: UUID | null, tab?: string) => {
    if (id) {
      let url = `/karyo/micrographs/${id}`;
      if (tab) {
        url += `?tab=${tab}`;
      }
      navigate(url);
      onSelect && onSelect(id);
    }
  }

  const handleViewMode = (
    event: React.MouseEvent<HTMLElement>,
    newViewMode: ViewMode | null,
  ) => {
    if (newViewMode !== null) {
      setViewMode(newViewMode);
      setSearchParams({ 'mv': newViewMode });
    }
  };

  const handleFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUploadedCount(0);
    setUploading(true);
    setBadFiles([]);
    if (e.target.files) {
      setFiles(e.target.files);
      for (let i = 0; i < e.target.files.length; ++i) {
        uploadImage(token, e.target.files[i], sampleId!)
        .then(() => {
          setUploadedCount(n => n + 1);
        })
        .catch((error: Error) => {
          console.error(error);
          setUploadedCount(n => n + 1);
          setBadFiles((f) => [...f, { name: e.target.files![i].name, message: error.message }]);
        });
      }
    }
  }

  useEffect(() => {
    if (uploading && uploadedCount === files?.length) {
      setUploading(false);
    }
    if (!uploading) {
      let ignore = false;
      const load = async () => {
        setLoading(true);
        setUploadedData(undefined);
        try {
          const uploads = await listThumbnails(token, { sample_id: sampleId!, size: THUMBNAIL_SIZE });
          if (!ignore) setUploadedData(uploads);
        } catch (e) {
          if (!ignore) setUploadedData(undefined);
          console.error(e);
        }
        try {
          const sample = await getSample(token, sampleId!);
          if (!ignore) setSample(sample);
        } catch (e) {
          if (!ignore) setSample(undefined);
          console.error(e);
        }
      }
      load();
      setLoading(false);
      return () => { ignore = true };
    }
  }, [token, sampleId, uploading, uploadedCount, files]);

  const view = (() => {
    switch (viewMode) {
      case 'list': return (
        <UploadGallery
          sampleContent={uploadedData}
          thumbnailSize={THUMBNAIL_SIZE}
          onSelect={handleUploadId}
        />
      );
      case 'grid': return (
        <CompactGallery
          sampleContent={uploadedData}
          thumbnailSize={THUMBNAIL_SIZE}
          onSelect={handleUploadId}
        />
      );
      default: return <Alert severity="error">Invalid view mode.</Alert>
    }
  })();

  const pageContent = (
    sampleId
    ? <Card sx={{ p: 2, ...sx }}>
        <CardHeader
        avatar={
          <ToggleButtonGroup
            value={viewMode}
            exclusive
            onChange={handleViewMode}
            aria-label="gallery view mode"
          >
            <ToggleButton value="list" aria-label="list">
              <ViewListIcon />
            </ToggleButton>
            <ToggleButton value="grid" aria-label="grid">
              <ViewModuleIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        }
          action={
            <Stack direction="row" gap={10} >

              <Button
                color="primary"
                aria-label="add patient"
                startIcon={<AddPhotoAlternateOutlined />}
                onClick={()=>fileInput.current ? fileInput.current.click() : null}
              >
                Upload photos
                <input ref={ref => fileInput.current = ref} hidden accept="image/*" type="file" multiple onChange={handleFiles}/>
              </Button>
            </Stack>
          }
          sx={{ height: '5em' }}
        />
        <CardContent sx={{ /*height: 'calc(100% - 5em)',*/ overflow: 'auto' }}>
          <Stack spacing={2}>
            {
              uploading && files
              ? <Alert severity="info">
                  <AlertTitle>Uploading and processing {uploadedCount}/{files?.length} micrograph{files && files!.length > 1 ? 's' : ''} in progress</AlertTitle>
                  <LinearProgress variant="determinate" value={uploadedCount / files!.length * 100} sx={{ flex: 1 }}/>
                </Alert>
              : null
            }
            {
              badFiles.map(f =>
                <ClosableAlert severity="error">
                  <AlertTitle>Couldn&apos;t process {f.name}</AlertTitle>
                  {f.message}
                </ClosableAlert>
              )
            }
            { view }
          </Stack>
        </CardContent>
      </Card>
    : <Typography variant="caption">Select a patient and a sample.</Typography>
  );

  console.log('#####', uploadedData)

  return (
    <Stack sx={{ width: '100%' }} direction="column" gap={2}>
      { sample &&
        <Card sx={{ p: 2, pb: 0 }}>
          <CardHeader
            title={`Sample: ${sample.sample_name}`}
            subheader={sample._id}
          />
          <CardContent>
            <List>
              <ListItem>
                Created at: {timestampToString(sample.created_at)}
              </ListItem>
              <ListItem>
                Last modified: {timestampToString(sample.updated_at)}
              </ListItem>
            </List>
          </CardContent>
        </Card>
      }
      { pageContent }
    </Stack>
  );
}
