import { useEffect, useState } from "react";
import { CreatePatientDto, PatientDto, UUID, createPatient, getPatients } from "../data/service";
import { useToken } from "../data/hooks";
import Button from "@mui/material/Button";
import PersonAddAlt1 from "@mui/icons-material/PersonAddAlt1";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useForm } from "react-hook-form";
import { FormInputText } from "./FormInputText";
import {
  DataGrid,
  GridColDef,
  GridRowSelectionModel,
  useGridApiRef,
} from "@mui/x-data-grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Alert from "@mui/material/Alert";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";
import { SxProps } from "@mui/material/styles";

export interface PatientListProps {
  selectedId?: UUID;
  onSelect?: (patient: PatientDto) => any;
  sx?: SxProps;
}

export default function PatientList({
  selectedId,
  onSelect,
  sx,
}: PatientListProps) {
  const [token,] = useToken();
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [reload, setReload] = useState(true);

  const { handleSubmit, control, reset } = useForm<CreatePatientDto>();

  const handleAddPatient = () => {
    setAddDialogOpen(true);
  }

  const handleAddClose = () => {
    setAddDialogOpen(false);
  }

  const onPatientSubmit = (data: CreatePatientDto) => {
    createPatient(token, data)
    .then(() => setReload((r) => !r))
    .catch(console.error);
    setAddDialogOpen(false);
    reset();
  }

  return (
    <Card sx={{ p: 2, ...sx }}>
      <CardHeader
        title="Patients"
        action={
          <>
            <Button
              color="primary"
              onClick={handleAddPatient}
              aria-label="add patient"
              startIcon={<PersonAddAlt1 />}
            >
              Add patient
            </Button>
            <Dialog open={addDialogOpen} onClose={handleAddClose}>
              <DialogTitle>Add patient</DialogTitle>
              <DialogContent>
              <DialogContentText>
                Enter fake personal data.
              </DialogContentText>
              <FormInputText
                control={control}
                name="patient_name"
                label="Full name"
                required
              />
              <FormInputText
                control={control}
                name="hospital_id"
                label="Identifier code"
                required
              />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleAddClose}>Cancel</Button>
                <Button onClick={handleSubmit(onPatientSubmit)}>Submit</Button>
              </DialogActions>
            </Dialog>
          </>
        }
        sx={{ height: '5em' }}
      />
      <CardContent sx={{ height: 'calc(100% - 5em)' }}>
        <LoadPatientsTable
          reload={reload}
          selectedId={selectedId}
          onSelect={onSelect}
        />
      </CardContent>
    </Card>
  );
}

export interface LoadPatientsTableProps {
  selectedId?: UUID;
  onSelect?: (patient: PatientDto) => any;
  reload: boolean;
}

export function LoadPatientsTable({
  selectedId,
  onSelect,
  reload,
}: LoadPatientsTableProps) {

  const [token, ] = useToken();
  const [patients, setPatients] = useState<PatientDto[]>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    let ignore = false;
    const load = async () => {
      try {
        const p = await getPatients(token);
        if (!ignore) {
          setPatients(p);
        }
      } catch (err) {
        setError(true);
      }
    }
    load();
    setLoading(false);
    return () => { ignore = true };
  }, [token, reload]);

  if (loading || !patients) {
    return <GhostPatientTable />;
  }
  if (error) {
    return <Alert severity="error">Could&apos;t load patient data.</Alert>;
  }
  return (
    <PatientTable
      patients={patients}
      selectedId={selectedId}
      onSelect={onSelect}
    />
  );
}

export interface PatientTableProps {
  selectedId?: UUID;
  patients: PatientDto[];
  onSelect?: (patient: PatientDto) => any;
}

export function PatientTable({
  selectedId,
  patients,
  onSelect,
}: PatientTableProps) {
  const gridApiRef = useGridApiRef();

  useEffect(() => {
    if (selectedId) {
      const rowIndex = patients.map(p => p._id).indexOf(selectedId);
      gridApiRef.current.scrollToIndexes({ rowIndex, colIndex: 0 });
    }
  }, [gridApiRef, selectedId, patients]);

  const columns: GridColDef[] = [
    { field: 'patient_name', headerName: 'Full name', flex: 3 },
    { field: 'hospital_id', headerName: 'Identifier code', flex: 2 },
  ];

  const handleSelect = (selection : GridRowSelectionModel) => {
    if (onSelect) {
      const selectedObj = patients.filter((p: PatientDto) => p._id === selection[0])![0]
      if (selectedObj) {
        onSelect(selectedObj);
      }
    }
  }

  return (
    patients.length
    ? <DataGrid
        apiRef={gridApiRef}
        rows={patients}
        getRowId={(row) => row._id}
        columns={columns}
        rowSelectionModel={selectedId ? [selectedId] : undefined}
        onRowSelectionModelChange={handleSelect}
      />
    : <Alert severity="info">No patients are registered yet.</Alert>
  );
}

function GhostPatientTable() {
  return (
    <Grid container spacing={1}>
      {
        [1, 2, 3, 4].map((e) => <Grid item xs={6} key={e}>
          <Skeleton variant="rectangular" height="2em" />
        </Grid>)
      }
    </Grid>
  );
}
