export type Token = string | null;
export type UUID = string;
export type Timestamp = string;
export type B64String = string;
export type PointDto = [number, number];
export type BBoxDto = [number, number, number, number];

export const API_ROOT = process.env.REACT_APP_API_ROOT || '';

export const buildUrl = (url: string) => `${API_ROOT}${API_ROOT.endsWith('/') || url.startsWith('/') ? '' : '/'}${url}`;

export interface CredentialsDto {
  email: string;
  password: string;
}

export interface UserDto {
  name: string;
  role: string;
}

export interface UserResponseDto {
  status: string;
  user: UserDto;
}

export interface AnnotationDto {
  uuid: UUID;
  bbox: BBoxDto;
  bbox_mode: number;
  category_id: number;
  segmentation: PointDto[][];
  segmentation_center: PointDto;
  area: number;
  iscrowd: boolean;
  score: number;
  suggestion:
    | 'duplication'
    | 'selected'
    | 'excluded'
    | 'user_edited'
    | 'deleted';
  rotation_angle: number;
  vertical_flip: boolean;
}

export interface DownloadImageRequestDto {
  upload_id: UUID;
  filetype:
    | 'uploaded'
    | 'corrected'
    | 'karyogram';
  size?: number;
}

export interface DownloadImageDto extends EntityMetaDto {
  local_filename: string;
  uploaded_filename: string;
  height: number;
  width: number;
  sample_id: UUID;
  b64_image: string;
  size?: number;
}

export interface PredictionsDto {
  annotations: AnnotationDto[];
  height: number;
  width: number;
  upload_id: UUID;
  operation_ids: UUID[];
  operation: string;
  snapshot_id: UUID;
}

interface SingleChromoOp {
  upload_id: UUID;
  uuid: UUID;
}

export interface DeleteDto extends SingleChromoOp {}

export interface MergeDto{
  upload_id: UUID;
  uuids: [UUID, UUID];
}

export interface SeparateDto extends SingleChromoOp {
  path: PointDto[];
}

export interface ExtendDrawDto extends SingleChromoOp {
  path: PointDto[];
}

export interface FreeDrawDto {
  upload_id: UUID;
  path: PointDto[];
}

export interface VerticalFlipDto extends SingleChromoOp {}

export interface RotateDto extends SingleChromoOp {
  angle: number;
};

export interface B64ImagesDto {
  [uuid: UUID]: ChromosomeImageDto;
}

export interface ChromosomeImageDto {
  url: string;
  width: number;
  height: number;
}

export interface ChangeCategoryDto {
  upload_id: UUID;
  chromo_boxes: ChromoBoxDto[];
}

export interface ChromoBoxDto {
  category_id: number;
  uuids: UUID[];
}

interface EntityMetaDto {
  _id: UUID;
  operation: string;
  user: UUID;
  created_at: Timestamp;
  updated_at: Timestamp;
}

export interface CreatePatientDto {
  patient_name: string;
  hospital_id: string;
  extra?: string;
}

export interface PatientDto extends CreatePatientDto, EntityMetaDto {}

export interface GetSampleDto {
  patient_id: UUID;
}

export interface CreateSampleDto extends GetSampleDto {
  sample_name: string;
}

export interface ListUploadsDto {
  sample_id: UUID;
}

export interface UploadDto extends EntityMetaDto {
  sample_id: UUID;
  patient_id: UUID;
  karyogram_filename?: string;
  upload_num: number;
}

export interface SampleDto extends CreateSampleDto, EntityMetaDto {}

export interface ListThumbnailsRequestDto {
  sample_id: UUID;
  size?: number;
}

export interface ListThumbnailsDto extends EntityMetaDto {
  sample_id: UUID;
  uploaded_filename: string;
  upload_num: number;
  corrected_b64_image: B64String;
  corrected_size: number;
  karyogram_b64_image?: B64String;
  karyogram_size?: number;
  segmented_b64_image?: B64String;
  ISCN_code?: string;
  diagnosis_comment?: string;
}

const handleResponse = (response: Response) => {
  if (response.ok) {
    return response.json();
  }
  console.error(response);
  throw new Error(response.statusText);
}

const callApi = <T, R>(
  method: 'GET' | 'POST',
  url: RequestInfo | URL,
  token: Token,
  data?: T
): Promise<R> =>
  fetch(url, {
    mode: 'cors',
    method: method,
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: data ? JSON.stringify(data) : undefined,
  })
  .then(handleResponse);

export const healthChecker = async (): Promise<any> =>
  fetch(`${API_ROOT}/api/healthchecker`, { method: 'GET' });

// User management

export const loginUser = async (credentials: CredentialsDto): Promise<string> => {
  return fetch(`${API_ROOT}/api/auth/login`, {
    //mode: 'cors',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
    .then(data => data?.access_token);
}

export const logoutUser = async (token: Token) => {
  return fetch(`${API_ROOT}/api/auth/logout`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
}

export const getUser = async (token: Token): Promise<UserResponseDto> =>
  callApi('GET', `${API_ROOT}/api/users/me`, token);

// Patient, sample and micrograph selection

export const createPatient = (token: Token, data: CreatePatientDto): Promise<any> =>
  callApi('POST', `${API_ROOT}/api/posts/patients`, token, data);

export const getPatients = (token: Token): Promise<PatientDto[]> =>
  callApi('GET', `${API_ROOT}/api/posts/patients`, token);

export const getPatient = (token: Token, patientId: UUID): Promise<PatientDto> =>
  callApi('GET', `${API_ROOT}/api/posts/patients/${patientId}`, token);

export const createSample = (token: Token, data: CreateSampleDto): Promise<any> =>
  callApi('POST', `${API_ROOT}/api/posts/samples`, token, data);

export const getSamples = (token: Token, query: GetSampleDto): Promise<SampleDto[]> =>
  callApi('GET', `${API_ROOT}/api/posts/samples?` + new URLSearchParams({
    patient_id: query.patient_id,
  }), token);

  export const getSample = (token: Token, sampleId: UUID): Promise<SampleDto> =>
  callApi('GET', `${API_ROOT}/api/posts/samples/${sampleId}`, token);

export const listThumbnails = (
  token: Token,
  query: ListThumbnailsRequestDto
): Promise<ListThumbnailsDto[]> =>
  callApi('GET', `${API_ROOT}/api/posts/samples/${query.sample_id}/thumbnails?` + (
    query.size ? new URLSearchParams({ size: String(query.size) }) : ''
  ), token);

export const getMicrographInfo = (token: Token, uploadId: UUID): Promise<UploadDto> =>
  callApi('GET', `${API_ROOT}/api/posts/uploads/${uploadId}/info`, token);

export interface Ids {
  patientId?: UUID;
  sampleId?: UUID;
  uploadId?: UUID;
}

export const getOtherIds = async (token: Token, ids: Ids): Promise<Ids> => {
  let res: Ids = {...ids};
  if (!!ids.uploadId) {
    const info = await getMicrographInfo(token, ids.uploadId);
    res.sampleId = info.sample_id;
    res.patientId = info.patient_id;
  }
  else if (!!ids.sampleId) {
    const info = await getSample(token, ids.sampleId);
    res.patientId = info.patient_id;
  }
  // If there's no uploadId, it can't be found out.
  return res;
}

// Chromosome and karyogram data

export const uploadImage = (
  token: Token,
  image: File,
  sampleId: UUID,
): Promise<PredictionsDto> => {
  let formData = new FormData();
  formData.append('file', image);

  return fetch(`${API_ROOT}/api/posts/uploads?` + new URLSearchParams({
    sample_id: sampleId,
  }), {
    mode: 'cors',
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`
    },
    body: formData,
  })
    .then(handleResponse);
}

export const downloadImage = (
  token: Token,
  data: DownloadImageRequestDto
): Promise<DownloadImageDto> =>
  callApi('GET', `${API_ROOT}/api/posts/uploads/${data.upload_id}/${data.filetype}?` + (
    data.size ? new URLSearchParams({ size: String(data.size) }) : ''
  ), token);

export const getChromosomeImages = (
  token: Token,
  uploadId: UUID,
): Promise<B64ImagesDto> =>
  callApi('GET', `${API_ROOT}/api/posts/uploads/${uploadId}/b64-images`, token);

export const getLastAnnotations = (
  token: Token,
  uploadId: UUID,
): Promise<PredictionsDto> =>
  callApi('GET', `${API_ROOT}/api/posts/uploads/${uploadId}/segmentation/snapshot`, token);

export const uploadKaryogram = (
  token: Token,
  uploadId: UUID,
  image: File,
): Promise<any> => {
  let formData = new FormData();
  formData.append('file', image);

  return fetch(`${API_ROOT}/api/posts/uploads/${uploadId}/karyogram`, {
    mode: 'cors',
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`
    },
    body: formData,
  })
    .then(handleResponse);
}

// Chromosome and karyogram editing operations

export const deleteChromosome = (
  token: Token,
  data: DeleteDto,
): Promise<PredictionsDto> =>
  callApi('POST', `${API_ROOT}/api/operations/delete`, token, data);

export const mergeChromosomes = (
  token: Token,
  data: MergeDto,
): Promise<PredictionsDto> =>
  callApi('POST', `${API_ROOT}/api/operations/merge`, token, data);

export const separateChromosomes = (
  token: Token,
  data: SeparateDto,
): Promise<PredictionsDto> =>
  callApi('POST', `${API_ROOT}/api/operations/separate`, token, data);

export const extendChromosome = (
  token: Token,
  data: ExtendDrawDto,
): Promise<PredictionsDto> =>
  callApi('POST', `${API_ROOT}/api/operations/extenddraw`, token, data);

export const drawChromosome = (
  token: Token,
  data: FreeDrawDto,
): Promise<PredictionsDto> =>
  callApi('POST', `${API_ROOT}/api/operations/freedraw`, token, data);

export const changeCategory = (
  token: Token,
  data: ChangeCategoryDto,
): Promise<PredictionsDto> =>
  callApi('POST', `${API_ROOT}/api/operations/change-category`, token, data);

export const verticalFlip = (
  token: Token,
  data: VerticalFlipDto,
): Promise<PredictionsDto> =>
  callApi('POST', `${API_ROOT}/api/operations/vertical-flip`, token, data);

export const rotate = (
  token: Token,
  data: RotateDto,
): Promise<PredictionsDto> =>
  callApi('POST', `${API_ROOT}/api/operations/rotate`, token, data);
