import Karyogram from "./Karyogram";

export default Karyogram;
export type {
  ChromosomeData,
  ChromosomePlacement,
  KeyedChromosomeData,
  UUID,
} from "./types";
export type {
  ChromosomeCardProps,
  ChromosomeImageProps,
  ChromosomeMenuCallback,
} from "../ChromosomeCard";
export * from "./constants";
