import { useEffect, useRef, useState } from "react";
import { useToken } from "../data/hooks";
import {
  B64ImagesDto,
  buildUrl,
  changeCategory,
  ChromoBoxDto,
  getChromosomeImages,
  PredictionsDto,
  rotate,
  Token,
  uploadKaryogram,
  UUID,
  verticalFlip,
} from "../data/service";

import Karyogram, { ChromosomePlacement, CATEGORY_UNKNOWN, KeyedChromosomeData, ChromosomeMenuCallback, ChromosomeData } from "../core-components/Karyogram";
import Box from "@mui/material/Box";
import Flip from "@mui/icons-material/Flip";
import RotateLeft from "@mui/icons-material/RotateLeft";
import RotateRight from "@mui/icons-material/RotateRight";
import DomToImage from "dom-to-image";
import { saveAs } from 'file-saver';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { AuthorizedImage } from "../core-components";
import Slider from "@mui/material/Slider";
import Stack from "@mui/material/Stack";
import { SxProps } from "@mui/material/styles";

export interface KaryogramEditorProps {
  uploadId: UUID;
  lastSnapshot: PredictionsDto;
  setLastSnapshot: React.Dispatch<React.SetStateAction<PredictionsDto | undefined>>;
  setHoveredChromosomeId?: React.Dispatch<React.SetStateAction<UUID | undefined>>;
  onFinalized?: () => void;
  sx?: SxProps;
}

export default function KaryogramEditor({
  uploadId,
  lastSnapshot,
  setLastSnapshot,
  setHoveredChromosomeId,
  onFinalized,
  sx,
} : KaryogramEditorProps) {
  const [token, ] = useToken();
  const [segmentImages, setSegmentImages] = useState<B64ImagesDto>();

  const [chromoData, setChromoData] = useState<KeyedChromosomeData>();
  const [chromoPlacement, setChromoPlacement] = useState<ChromosomePlacement>();

  const [forImageExport, setForImageExport] = useState(false);

  const karyogramNode = useRef<HTMLElement>(null);

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedChromosome, setSelectedChromosome] = useState<ChromosomeData>();

  useEffect(() => {
    getChromosomeImages(token, uploadId)
    .then(setSegmentImages)
    .catch(console.log);
  }, [token, uploadId]);

  useEffect(() => {
    const generateKaryogramSlotData = (segmentImages: B64ImagesDto): [KeyedChromosomeData, ChromosomePlacement] => {
      let data: KeyedChromosomeData = {};
      let placement: ChromosomePlacement = {};
      Object.keys(segmentImages)
        .forEach((uuid) => {
          const annot = lastSnapshot?.annotations.filter(a => a.uuid === uuid)[0]!;
          const { url, width, height } = segmentImages[uuid];
          data[uuid] = {
            key: uuid,
            imageUrl: buildUrl(url),
            edited: annot.suggestion === 'user_edited',
            rotation: annot.rotation_angle,
            verticalFlip: annot.vertical_flip,
            width: width,
            height: height,
          };
          const categoryKey = `${annot?.category_id ?? CATEGORY_UNKNOWN}`;
          placement[categoryKey] = [...(placement[categoryKey] || []), uuid];
      });
      return [data, placement];
    }

    if (segmentImages && lastSnapshot) {
      const [d, p] = generateKaryogramSlotData(segmentImages);
      setChromoData(d);
      setChromoPlacement(p);
    }
  }, [segmentImages, lastSnapshot]);

  const handleSaveAsImage = () => {
    setForImageExport(true);
  }

  useEffect(() => {
    if (karyogramNode.current && forImageExport) {
      DomToImage.toBlob(karyogramNode.current)
      .then(function (blob) {
          uploadKaryogram(
            token,
            uploadId,
            new File([blob], `karyogram_${uploadId}.png`)
          ).then(() => {
            saveAs(blob, `karyogram_${uploadId}.png`);
            onFinalized && onFinalized();
          })
          .then(() => {
            setForImageExport(false);
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
        setForImageExport(false);
      });
    }
  }, [forImageExport, token, uploadId]);

  const handleChangeCategory = (data: ChromosomePlacement) => {
    let chromoBoxes: ChromoBoxDto[] = [];
    Object.keys(data).forEach(categoryId => {
      chromoBoxes.push({
        category_id: +categoryId,
        uuids: data[categoryId].map(id => String(id)),
      });
    });
    changeCategory(token, {
      upload_id: uploadId,
      chromo_boxes: chromoBoxes,
    })
    .then(setLastSnapshot)
    .catch(console.error);
  }

  const rotateByUuid = (uuid: UUID, angle: number) => {
    return rotate(token!, {upload_id: uploadId, uuid, angle: angle})
        .then((data: PredictionsDto) => {
          setLastSnapshot(data);
        })
        .catch(console.error);
  }

  const rotateChromo = (chromosome: ChromosomeData, angle: number) => {
    // Immediate transformation for UX
    setLastSnapshot((d) => {
      const mod: PredictionsDto = {...d!};
      const annot = mod!.annotations!.find(a => a.uuid === chromosome.key);
      if (annot) {
        annot.rotation_angle += angle;
      }
      return mod;
    });
    // Server side
    return rotateByUuid(String(chromosome.key), angle);
  }

  const menuFunctions: ChromosomeMenuCallback[] = [
    {
      label: 'Flip',
      icon: <Flip/>,
      callback: (chromosome: ChromosomeData) => {
        setLastSnapshot((d) => {
          const mod: PredictionsDto = {...d!};
          const annot = mod!.annotations!.find(a => a.uuid === chromosome.key);
          if (annot) {
            annot.vertical_flip = !annot.vertical_flip;
          }
          return mod;
        });
        return verticalFlip(token, {upload_id: uploadId, uuid: String(chromosome.key)})
          .then((data: PredictionsDto) => {
            setLastSnapshot(data);
          })
          .catch(console.error);
      },
    },
    {
      label: 'Rotate 90˚ CCW',
      icon: <RotateLeft/>,
      callback: (chromosome: ChromosomeData) => rotateChromo(chromosome, 90),
    },
    {
      label: 'Rotate 90˚ CW',
      icon: <RotateRight/>,
      callback: (chromosome: ChromosomeData) => rotateChromo(chromosome, -90),
    },
    {
      label: 'Rotate 180˚',
      icon: <RotateRight/>,
      callback: (chromosome: ChromosomeData) => rotateChromo(chromosome, 180),
    },
    {
      label: 'Free rotate',
      icon: <RotateRight/>,
      callback: (chromosome: ChromosomeData) => {
        setSelectedChromosome(chromosome);
        setEditDialogOpen(true);
        return new Promise((resolve, reject) => resolve(true));
      },
    },
  ];

  const chromoImageProps = {
    onDoubleClick: (ev: React.MouseEvent<HTMLElement>) => {
      rotateByUuid(ev.currentTarget.dataset.uuid!, 180);
    },
    onMouseEnter: (ev: React.MouseEvent<HTMLElement>) =>
      setHoveredChromosomeId && setHoveredChromosomeId(ev.currentTarget.dataset.uuid!),
    onMouseLeave: (ev: React.MouseEvent<HTMLElement>) =>
      setHoveredChromosomeId && setHoveredChromosomeId(undefined),
  };

  const handleEditDialogClose = (rotateDegree?: number) => {
    setSelectedChromosome(undefined);
    setEditDialogOpen(false);
    // This "if" is OK, both undefined and zero should be skipped
    if (rotateDegree) {
      return rotate(token!, {upload_id: uploadId, uuid: String(selectedChromosome?.key), angle: rotateDegree})
      .then((data: PredictionsDto) => {
        setLastSnapshot(data);
      })
      .catch(console.log);
    }
  }

  const chromoSizes = (() => {
    const r: {[uuid: UUID]: number} = {};
    lastSnapshot?.annotations.forEach((a) => {
      const [x0, y0, x1, y1] = a.bbox;
      const h = y1 - y0;
      const w = x1 - x0;
      r[a.uuid] = Math.sqrt(h*h + w*w);
    })
    return r;
  })();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      sx={sx}
      gap={2}
    >
      {
        (chromoData && chromoPlacement)
        ?
          <>
            <Karyogram
              ref={karyogramNode}
              token={token!}
              chromosomeData={chromoData}
              chromosomePlacement={chromoPlacement}
              onChangePlacement={handleChangeCategory}
              chromosomeMenuCallbacks={menuFunctions}
              chromosomeImageProps={chromoImageProps}
              forImageExport={forImageExport}
            />
            <Button
              color="warning"
              onClick={handleSaveAsImage}
              aria-label="finalize karyogram"
              startIcon={<FactCheckIcon />}
              variant="contained"
            >
              Finalize karyogram
            </Button>
          </>
        : <CircularProgress />
      }
      {
        selectedChromosome && <ChromosomeEditDialog
          id="chromosome-edit-dialog"
          token={token!}
          chromosome={selectedChromosome}
          size={chromoSizes[selectedChromosome.key]}
          keepMounted
          open={editDialogOpen}
          onClose={handleEditDialogClose}
        />
      }
    </Box>
  );
}

interface ChromosomeEditDialogProps {
  token: Token;
  chromosome: ChromosomeData;
  size: number;
  id: string;
  keepMounted: boolean;
  open: boolean;
  onClose: (value?: number) => any;
}

function ChromosomeEditDialog(props: ChromosomeEditDialogProps) {
  const { onClose, open, ...other } = props;
  const [value, setValue] = useState(0);
  const radioGroupRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (!open) {
      setValue(0);
    }
  }, [open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose(undefined);
  };

  const handleOk = () => {
    onClose(value);
  };

  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number);
  };

  const marks = [-180, -135, -90, -45, 0, 45, 90, 135, 180].map(deg => ({value: deg, label: `${deg}˚`}));

  // CSS rotation and API rotation is in the opposite direction, so here negated
  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%' } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <DialogTitle>Freehand chromosome rotation</DialogTitle>
      <DialogContent dividers>
        <Stack>
          <Box
            sx={{
              minHeight: props.size,
              minWidth: props.size,
              justifyContent: 'center',
              display: 'flex',
              p: 2,
            }}
          >
            <AuthorizedImage
              url={props.chromosome.imageUrl}
              key={props.chromosome.key}
              token={props.token!}
              style={{
                transform: `rotate(${-((props.chromosome.rotation ?? 0) + value)}deg)`,
                alignSelf: 'center'
              }}
            />
          </Box>
          <Stack direction="row" gap={1} sx={{ pt: 6 }}>
            <RotateRight />
            <Slider
              value={value}
              onChange={handleChange}
              min={-180}
              max={180}
              valueLabelDisplay="auto"
              marks={marks}
            />
            <RotateLeft />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={handleOk}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
}
