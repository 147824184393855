import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { AuthorizedImage } from "../core-components/AuthorizedImage";
import { Token } from "../data/service";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import React, { useCallback, useRef } from "react";
import { grey } from "@mui/material/colors";

export interface MicrographViewProps {
  imageUrl: string | undefined;
  token: Token;
  imageWidth: number;
  imageHeight: number,
  props?: any;
}

export default function MicrographView({
  imageUrl,
  token,
  imageHeight,
  imageWidth,
  props,
}: MicrographViewProps) {
  const wrapperRef = useRef<HTMLElement>();
  const contentRef = useRef<HTMLElement>();

  const fitContentToWrapper = useCallback(
    (centerView: (scale: number) => void) => {
      if (wrapperRef.current && contentRef.current) {
        const wrapperWidth = wrapperRef.current.clientWidth;
        const wrapperHeight = wrapperRef.current.clientHeight;

        const contentWidth = contentRef.current.clientWidth;
        const contentHeight = contentRef.current.clientHeight;

        const widthScale = wrapperWidth / contentWidth;
        const heightScale = wrapperHeight / contentHeight;

        const scale = widthScale < heightScale ? widthScale : heightScale;

        centerView(scale);
      }
    },
    []
  );

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="stretch"
      flexDirection="column"
      border="solid 1px"
      borderColor={grey[400]}
      {...props}
      ref={wrapperRef}
    >
      {
        imageUrl
        ? <TransformWrapper doubleClick={{ mode: 'reset' }}>
            {({ centerView }) => (
              <TransformComponent wrapperStyle={{ width: '100%', height: '100%', transformOrigin: 'center' }} >
                <AuthorizedImage ref={contentRef} url={imageUrl} token={token!} width={imageWidth} height={imageHeight} onLoad={() => fitContentToWrapper(centerView)}/>
              </TransformComponent>
            )}
          </TransformWrapper>
        : <CircularProgress />
      }
    </Box>
  );
}
