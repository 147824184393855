import AppBar from "@mui/material/AppBar";
import { useToken } from "../data/hooks";
import { stringAvatar } from '../utils/utils';
import Toolbar from "@mui/material/Toolbar";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Chip from "@mui/material/Chip";
import Menu from "@mui/material/Menu";
import Box from "@mui/material/Box";
import { LoadSamplesTable } from "./SampleList";
import { bindMenu, bindContextMenu } from "material-ui-popup-state";
import { LoadPatientsTable } from "./PatientList";

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import LogoutIcon from '@mui/icons-material/Logout';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { PatientDto, SampleDto, UploadDto, UserDto, getMicrographInfo, getOtherIds, getPatient, getSample, getUser, logoutUser } from "../data/service";
import { usePopupState } from "material-ui-popup-state/hooks";

import Fab from "@mui/material/Fab";


export function NavFrame({ children } : { children: any }) {
  const [token, setToken] = useToken();
  const { patientId, sampleId, uploadId } = useParams();

  const navigate = useNavigate();

  const [currentUser, setCurrentUser] = useState<UserDto>();
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState<PatientDto>();
  const [selectedSample, setSelectedSample] = useState<SampleDto>();
  const [selectedUploadId, setSelectedUploadId] = useState<string>();
  const [selectedUpload, setSelectedUpload] = useState<UploadDto>();
  const patientPopupState = usePopupState({ variant: 'popover', popupId: `patient-menu` });
  const samplePopupState = usePopupState({ variant: 'popover', popupId: `sample-menu` });


  const handleLogout = () => {
    logoutUser(token);
    setSelectedPatient(undefined);
    setSelectedSample(undefined);
    setSelectedUploadId(undefined);
    setToken(null);
    setCurrentUser(undefined);
    navigate("/", { replace: true });
  }

  const handlePatient = (patient: PatientDto | null) => {
    setSelectedPatient(patient ?? undefined);
    setSelectedSample(undefined);
    setSelectedUploadId(undefined);
    if (patient) {
      navigate(`/karyo/patients/${patient!._id}`);
    }
    patientPopupState.close();
  }

  const handleBackToPatient = () => {
    setSelectedSample(undefined);
    setSelectedUploadId(undefined);
    if (selectedPatient) {
      navigate(`/karyo/patients/${selectedPatient!._id}`);
    }
  }

  const handleSelectedSample = (sample: SampleDto | null) => {
    setSelectedSample(sample ?? undefined);
    setSelectedUploadId(undefined);
    if (sample) {
      navigate(`/karyo/samples/${sample!._id}`);
    }
    samplePopupState.close();
  }

  const handleBackToSample = () => {
    setSelectedUploadId(undefined);
    if (selectedSample) {
      navigate(`/karyo/samples/${selectedSample!._id}`);
    }
  }

  const handleBackToAll = () => {
    setSelectedPatient(undefined);
    setSelectedSample(undefined);
    setSelectedUploadId(undefined);
    navigate(`/karyo/patients`);
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 300) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    });
  }, []);

  useEffect(() => {
    if (token) {
      getUser(token).then((user) => setCurrentUser(user.user));
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      getOtherIds(token, { patientId, sampleId, uploadId }).then((ids) => {
        setSelectedUploadId(ids.uploadId);
        if (ids.uploadId) {
          getMicrographInfo(token, ids.uploadId).then(setSelectedUpload);
        }
        if (ids.patientId) {
          getPatient(token, ids.patientId).then(setSelectedPatient);
        }
        if (ids.sampleId) {
          getSample(token, ids.sampleId).then(setSelectedSample);
        }
      });
    }
  }, [token, patientId, sampleId, uploadId]);


  const breadcrumbs = (
    token ?
    <Breadcrumbs
      sx={{ md: 'flex', flex: 1 }}
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      <div>
        <Chip
          label={`All patients`}
          onClick={handleBackToAll}
        />
      </div>
      <div>
        <Chip
          label={selectedPatient ? `Patient: ${selectedPatient?.patient_name}` : 'Select patient'}
          onClick={handleBackToPatient}
          {...bindContextMenu(patientPopupState)}
        />
        <Menu {...bindMenu(patientPopupState)} anchorReference="anchorEl">
          <Box sx={{ minWidth: '400px', p: 2 }}>
            <LoadPatientsTable
              onSelect={handlePatient}
              reload={true}
            />
          </Box>
        </Menu>
      </div>
      {
        selectedPatient
        ?
          <div>
            <Chip
              label={selectedSample ? `Sample: ${selectedSample?.sample_name}` : 'Select sample'}
              onClick={handleBackToSample}
              {...bindContextMenu(samplePopupState)}
            />
            <Menu {...bindMenu(samplePopupState)} anchorReference="anchorEl">
              <Box sx={{ minWidth: '400px', p: 2 }}>
                <LoadSamplesTable
                  patientId={selectedPatient._id}
                  onSelect={handleSelectedSample}
                  reload={true}
                />
              </Box>
            </Menu>
          </div>
        : null
      }
      {
        selectedUploadId
        ? <Chip label={`Micrograph: ${selectedUpload?.upload_num}`} />
        : null
      }
    </Breadcrumbs>
    : null
  );

  const appBar = (
    <AppBar position="sticky" color="default">
      <Toolbar>
        <Stack direction="row" gap={4} flexGrow={1}>
          <Typography sx={{ fontWeight: 'bold' }}>Chromosome AI {process.env.REACT_APP_VERSION}</Typography>
          {breadcrumbs}
        </Stack>
        {
          currentUser
          ? <Stack direction="row" gap={2} alignItems="center">
              <Avatar {...stringAvatar(currentUser!.name)} />
              <Typography>{currentUser!.name}</Typography>
              <IconButton onClick={handleLogout} color="inherit">
                <LogoutIcon />
              </IconButton>
            </Stack>
          : null
        }
      </Toolbar>
    </AppBar>
  );

  return (
    <Stack spacing={1}>
      { appBar }
      <Box sx={{ pt: "1rem", pb: 2, pr: 2, pl: 2, display: 'flex', justifyContent: 'center' }}>
        { children }
      </Box>
      {
        showScrollButton && <Fab
          onClick={scrollToTop}
          color='primary'
          sx={{
            position: 'fixed',
            bottom: 32,
            right: 32,
          }}
        >
          <ArrowUpwardIcon />
        </Fab>
      }
    </Stack>
  );
}
