import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import type { RootState, AppDispatch } from './store'
import { useState } from 'react';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export const useToken = (): [string | null, (t: string | null) => void] => {
  const getToken = () => {
    const userToken = sessionStorage.getItem('token');
    return userToken;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken: string | null): void => {
    if (userToken) {
      sessionStorage.setItem('token', userToken);
    } else {
      sessionStorage.removeItem('token');
    }

    setToken(userToken);
  };

  return [token, saveToken];
}
