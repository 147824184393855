import ImageList from "@mui/material/ImageList";
import { ListThumbnailsDto, Token, UUID, buildUrl } from "../data/service";
import ImageListItem from "@mui/material/ImageListItem";
import { useToken } from "../data/hooks";
import React, { useCallback, useEffect, useState } from "react";
import { AuthorizedImage } from "../core-components";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import BiotechIcon from '@mui/icons-material/Biotech';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import AlignVerticalBottomIcon from '@mui/icons-material/AlignVerticalBottom';
import Popper from "@mui/material/Popper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { timestampToString } from "../utils/utils";
import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
import Alert from "@mui/material/Alert";

export interface CompactGalleryProps {
  sampleContent: ListThumbnailsDto[] | undefined;
  thumbnailSize: number;
  onSelect?: (uploadId: UUID, tab?: string) => any;
}

type WhichThumbnail = 'corrected_b64_image' | 'karyogram_b64_image' | 'segmented_b64_image';

/**
 * Displays the selected type of thumbnails in a compact layout.
 *
 * * Switch thumbnail type [TODO]
 * * Switch column number [TODO]
 */
export default function CompactGallery({
  sampleContent,
  thumbnailSize,
  onSelect,
}: CompactGalleryProps) {
  const [token, ] = useToken();
  const [whichThumbnail, setWhichThumbnail] = useState<WhichThumbnail>('corrected_b64_image');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleWindowResize = useCallback((event: Event) => {
    setWindowWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [handleWindowResize]);

  const gap = 8;
  const cols = Math.floor(windowWidth / (thumbnailSize + 4*gap));

  const handleSelect = (uploadId: UUID, tab?: string) => onSelect && onSelect(uploadId, tab);

  if (sampleContent === undefined) {
    return <GhostGallery cols={cols} gap={gap} thumbnailSize={thumbnailSize} />;
  }
  return (
    sampleContent.length
    ? <Stack gap={1}>
        <ToggleButtonGroup
          value={whichThumbnail}
          exclusive
          onChange={(_, value) => setWhichThumbnail(value)}
          aria-label="gallery view mode"
        >
          <ToggleButton value="corrected_b64_image" aria-label="corrected">
            <BiotechIcon />
          </ToggleButton>
          <ToggleButton value="segmented_b64_image" aria-label="segmented">
            <AutoFixHighIcon />
          </ToggleButton>
          <ToggleButton value="karyogram_b64_image" aria-label="karyogram">
            <AlignVerticalBottomIcon />
          </ToggleButton>
        </ToggleButtonGroup>
        <ImageList cols={cols} gap={gap}>
          {sampleContent.map((item) => <GalleryItem
            key={item._id}
            item={item}
            thumbnailSize={thumbnailSize}
            token={token}
            whichThumbnail={whichThumbnail}
            onSelect={handleSelect}
          />)}
        </ImageList>
      </Stack>
    : <Alert severity="info">This sample doesn&apos;t contain micrographs yet.</Alert>
  );
}

function GalleryItem({
  item,
  whichThumbnail,
  thumbnailSize,
  token,
  onSelect,
}: {
  item: ListThumbnailsDto;
  whichThumbnail: WhichThumbnail;
  thumbnailSize: number;
  token: Token;
  onSelect: (uploadId: UUID, tab?: string) => any;
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const [imageUrl, tab] = (() => {
    switch (whichThumbnail) {
      case 'corrected_b64_image': return [item.corrected_b64_image, 'micrograph'];
      case 'segmented_b64_image': {
        if (item.segmented_b64_image) {
          return [item.segmented_b64_image, 'segmentation'];
        } else {
          return [item.corrected_b64_image, 'micrograph'];
        }
      }
      case 'karyogram_b64_image': {
        if ( item.karyogram_b64_image) {
          return [item.karyogram_b64_image, 'karyogram'];
        } else if (item.segmented_b64_image) {
          return [item.segmented_b64_image, 'segmentation'];
        } else {
          return [item.corrected_b64_image, 'micrograph'];
        }
      }
    }
  })();

  return (
    <ImageListItem
      key={item._id}
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
      sx={{
        border: '1px solid black',
        borderRadius: 2,
        alignItems: 'center',
        justifyContent: 'center',
        p: 1,
        cursor: 'pointer',
      }}
      onClick={() => onSelect(item._id, tab)}
    >
      <AuthorizedImage
        token={token!}
        url={buildUrl(imageUrl)}
        alt={item._id}
        loading="lazy"
      />
      <ImageListItemBar
        position="top"
        title={<Avatar sx={{ bgcolor: 'secondary.main' }}>{item.upload_num}</Avatar>}
        sx={{ backgroundColor: 'transparent' }}
      />
      <Popper
        id={`mouse-over-popover-${item._id}`}
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}

      >
        <Paper>
          <List dense>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText primary={item.ISCN_code} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText primary={item.uploaded_filename} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText primary={timestampToString(item.created_at)} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText primary={timestampToString(item.updated_at)} />
              </ListItemButton>
            </ListItem>
          </List>
        </Paper>
      </Popper>
    </ImageListItem>
  );
}

function GhostGallery({ cols, gap, thumbnailSize }: {
  cols: number;
  gap: number;
  thumbnailSize: number;
}) {
  return (
    <ImageList cols={cols} gap={gap}>
      {
        Array.from(Array(5).keys()).map((key) => (
          <ImageListItem key={key} >
            <Skeleton variant="rectangular" width={thumbnailSize} height={thumbnailSize}/>
          </ImageListItem>
        ))
      }
    </ImageList>
  );
}
