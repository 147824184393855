import Box from "@mui/material/Box";
import { useCallback, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector, useToken } from '../data/hooks';
import {
  buildUrl,
  deleteChromosome,
  downloadImage,
  drawChromosome,
  extendChromosome,
  getLastAnnotations,
  mergeChromosomes,
  PredictionsDto,
  separateChromosomes,
  UUID
} from "../data/service";

import './SegmentationEditor.css';
import { selectTransform, setTransform } from "../data/imageSlice";
import SegmentationPlot, { ChromosomeOps, EditToolResult } from "../components/SegmentationPlot";
import CircularProgress from "@mui/material/CircularProgress";
import { grey } from "@mui/material/colors";

export interface SegmentationEditorProps {
  id: string;
  uploadId: UUID;
  lastSnapshot: PredictionsDto;
  setLastSnapshot: React.Dispatch<React.SetStateAction<PredictionsDto | undefined>>;
  props?: any;
}

export default function SegmentationEditor({
  id,
  uploadId,
  lastSnapshot,
  setLastSnapshot,
  props,
}: SegmentationEditorProps) {
  const [token, ] = useToken();

  const [image, setImage] = useState<string>();
  const dispatch = useAppDispatch();
  const transform = useAppSelector(selectTransform);

  const wrapperRef = useRef<HTMLElement>();

  const fitContentToWrapper = useCallback(
    () => {
      if (wrapperRef.current && lastSnapshot.width && lastSnapshot.height) {
        const wrapperWidth = wrapperRef.current.clientWidth;
        const wrapperHeight = wrapperRef.current.clientHeight;

        const contentWidth = lastSnapshot.width;
        const contentHeight = lastSnapshot.height;

        const widthScale = wrapperWidth / contentWidth;
        const heightScale = wrapperHeight / contentHeight;

        let scale, trX, trY;
        if (widthScale < heightScale) {
          scale = widthScale;
          trX = 0;
          trY = (wrapperHeight - scale * contentHeight) / 2;
        } else {
          scale = heightScale;
          trX = (wrapperWidth - scale * contentWidth) / 2;
          trY = 0;
        }

        dispatch(setTransform(`translate(${trX}, ${trY}) scale(${scale})`));
      }
    },
    [lastSnapshot.width, lastSnapshot.height, dispatch]
  );

  const chromosomeOps: ChromosomeOps = {
    merge: (r: EditToolResult) => {
      return mergeChromosomes(token, {
        uuids: [r.uuids![0], r.uuids![1]],
        upload_id: uploadId,
      })
      .then((p: PredictionsDto) => {
        if (r.transform) {
          dispatch(setTransform(r.transform));
        }
        setLastSnapshot(p);
      })
      .catch(console.log);
    },
    separate: (r: EditToolResult) => {
      return separateChromosomes(token, {
        uuid: r.uuids![0],
        upload_id: uploadId,
        path: r.path || []
      })
      .then((p: PredictionsDto) => {
        if (r.transform) {
          dispatch(setTransform(r.transform));
        }
        setLastSnapshot(p);
      })
      .catch(console.log);
    },
    delete: (r: EditToolResult) => {
      return deleteChromosome(token, {
        uuid: r.uuids![0],
        upload_id: uploadId,
      })
      .then((p: PredictionsDto) => {
        if (r.transform) {
          dispatch(setTransform(r.transform));
        }
        setLastSnapshot(p);
      })
      .catch(console.log);
    },
    extend: (r: EditToolResult) => {
      return extendChromosome(token, {
        uuid: r.uuids![0],
        upload_id: uploadId,
        path: r.path || []
      })
      .then((p: PredictionsDto) => {
        if (r.transform) {
          dispatch(setTransform(r.transform));
        }
        setLastSnapshot(p);
      })
      .catch(console.log);
    },
    freeSelect: (r: EditToolResult) => {
      return drawChromosome(token, {
        upload_id: uploadId,
        path: r.path || []
      })
      .then((p: PredictionsDto) => {
        if (r.transform) {
          dispatch(setTransform(r.transform));
        }
        setLastSnapshot(p);
      })
      .catch(console.log);
    },
  }

  useEffect(() => {
    getLastAnnotations(token, uploadId)
    .then((p) => setLastSnapshot(p))
    .catch(console.error);
    downloadImage(token, {upload_id: uploadId, filetype: 'corrected'})
    .then((res) => setImage(buildUrl(res.b64_image)))
    .catch(console.error);
  }, [token, uploadId, setLastSnapshot]);

  useEffect(() => {
    fitContentToWrapper()
  }, [fitContentToWrapper]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="stretch"
      flexDirection="column"
      border="solid 1px"
      borderColor={grey[400]}
      {...props}
      ref={wrapperRef}
      sx={{ height: '100%' }}
    >

        {
          image && lastSnapshot
          ? <SegmentationPlot
              token={token!}
              image={image}
              predictions={lastSnapshot}
              transform={transform}
              chromosomeOps={chromosomeOps}
            />
          : <CircularProgress />
        }
    </Box>
  )
}
