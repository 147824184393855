import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from './store';

// Define a type for the slice state

interface ImageState {
  transform: string | null;
}

// Define the initial state using that type
const initialState: ImageState = {
  transform: null,
}

export const imageSlice = createSlice({
  name: 'image',
  initialState,
  reducers: {
    setTransform: (state, action: PayloadAction<string>) => {
      state.transform = action.payload;
    },
  },
})

export const { setTransform } = imageSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectTransform = (state: RootState) => state.image.transform;

export default imageSlice.reducer;
