export function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(name: string) {
  const monogram = (name || '').split(' ').filter(e => !!e).map(e => e[0]).join('');
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: monogram || '?',
  };
}

export function timestampToString(t: string) {
  const dateTime = new Date(Date.parse(t));
  const date = dateTime.toLocaleDateString();
  const time = dateTime.toLocaleTimeString(undefined, { timeStyle: 'medium' });
  return `${date} ${time}`;
}

export function deg2rad(deg: number): number {
  return deg * Math.PI / 180;
}

export function calcRotatedSize(h: number, w: number, deg: number): { width: number; height: number } {
  const phi = deg2rad(deg % 360);
  const cw = Math.abs(Math.cos(phi) * w) + Math.abs(Math.sin(phi) * h);
  const ch = Math.abs(Math.sin(phi) * w) + Math.abs(Math.cos(phi) * h);
  return { width: cw, height: ch };
}
