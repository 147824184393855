import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LoginIcon from '@mui/icons-material/Login';

import { useState, MouseEvent, FormEvent, useEffect } from "react";

import { API_ROOT, healthChecker, loginUser } from "../data/service";
import Fab from "@mui/material/Fab";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import RefreshIcon from '@mui/icons-material/Refresh';
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";

interface Params {
  setToken: (t: any) => void;
  toNext: () => void;
}

export default function LoginPage({ setToken, toNext }: Params) {
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loginError, setLoginError] = useState(false);
  const [networkError, setNetworkError] = useState(false);

  const checkApi = async () => {
    setLoading(true);
    try {
      await healthChecker();
      setNetworkError(false);
    } catch (e) {
      setNetworkError(true);
    } finally {
      setLoading(false);
    }
  }

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (email && password) {
      const token = await loginUser({
        email,
        password
      });
      setToken(token);
      if (token) {
        setLoginError(false);
        toNext();
      } else {
        setLoginError(true);
      }
    }
  }

  useEffect(() => {
    checkApi();
  }, []);

  const title = (
    <Typography
      variant="h1"
      color="primary.dark"
      sx={{ fontWeight: 400 }}
    >
      Chromosome AI
    </Typography>
  );

  const loginForm = (
    <form onSubmit={handleSubmit}>
      <Box sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        alignContent: 'center',
        gap: 1,
      }}>
        <FormControl sx={{ m: 1, width: 1 }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-username">E-mail</InputLabel>
          <OutlinedInput
            id="outlined-adornment-username"
            aria-describedby="outlined-username-helper-text"
            inputProps={{
              'aria-label': 'username',
            }}
            label="E-mail"
            onChange={e => setEmail(e.target.value)}
          />
        </FormControl>
        <FormControl sx={{ m: 1, width: 1 }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
            onChange={e => setPassword(e.target.value)}
          />
        </FormControl>
        {
          loginError
          ? <Alert severity="error" sx={{ width: 1 }}>Your credentials are invalid. Please try again.</Alert>
          : null
        }
        <Fab type="submit" aria-label="login" color="primary">
          <LoginIcon />
        </Fab>
      </Box>
    </form>
  );

  const loginGhost = (
    <Box sx={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      alignContent: 'center',
      gap: 1,
    }}>
      <Skeleton width="100%" height="3rem" variant="rounded"/>
      <Skeleton width="100%" height="3rem" variant="rounded"/>
      <Skeleton width="4rem" height="4rem" variant="circular"/>
    </Box>
  );

  const networkAlert = (
    <Alert
      severity="error"
      action={
        <Tooltip title="Try again">
          <IconButton color="inherit" size="small" onClick={checkApi}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      }
    >
      <AlertTitle>Network error</AlertTitle>
      The service at {API_ROOT} is currently not available.
    </Alert>
  );

  return (
    <Container  sx={{
      minHeight: '100vh',
      minWidth: '100vw',
      display: 'flex',
      direction: "column",
      alignItems: 'center',
      justifyContent: "center",
    }}>
      <Stack gap={2} alignItems="center">
        { title }
        <Alert severity="info" sx={{ width: "500px" }}>UI: {process.env.REACT_APP_VERSION}. API: {API_ROOT}</Alert>
        <Box width="500px">
          {
            loading
            ? loginGhost
            : (networkError ? networkAlert : loginForm)
          }
        </Box>
      </Stack>
    </Container>
  );
}
