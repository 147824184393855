import React from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { useToken } from '../data/hooks';
import './App.scss';
import LoginPage from './LoginPage';
import { ProtectedRoute } from '../components/ProtectedRoute';
import CssBaseline from '@mui/material/CssBaseline';
import SampleDetailsPage from './SampleDetailsPage';
import PatientsPage from './PatientsPage';
import PatientDetailsPage from './PatientDetailsPage';
import UploadDetailsPage from './UploadDetailsPage';
import { NavFrame } from '../components/NavFrame';

export default function App() {
  const [, setToken] = useToken();
  const navigate = useNavigate();

  const { state } = useLocation();

  const handleLogin = () => {
    console.log(state);
    if (state && state.from) {
      navigate(state.from.pathname, { replace: true });
    } else {
      navigate('/karyo/patients', { replace: true });
    }
  }

  const loginPage = (
    <LoginPage
      setToken={setToken}
      toNext={handleLogin}
    />
  );

  return (
    <React.Fragment>
      <CssBaseline />
      <Routes>
        <Route
          path="/"
          element={loginPage}
        />
        <Route
          path="/karyo/patients"
          element={<ProtectedRoute><NavFrame><PatientsPage /></NavFrame></ProtectedRoute>}
        />
        <Route
          path="/karyo/patients/:patientId"
          element={<ProtectedRoute><NavFrame><PatientDetailsPage /></NavFrame></ProtectedRoute>}
        />
        <Route
          path="/karyo/samples/:sampleId"
          element={<ProtectedRoute><NavFrame><SampleDetailsPage /></NavFrame></ProtectedRoute>}
        />
        <Route
          path="/karyo/micrographs/:uploadId"
          element={<ProtectedRoute><NavFrame><UploadDetailsPage /></NavFrame></ProtectedRoute>}
        />
        <Route
          path="*"
          element={<NoMatch />}
        />
      </Routes>
    </React.Fragment>
  );
}

function NoMatch() {
  return <h3>404</h3>
}
