import { useState } from "react";
import { useToken } from "../../data/hooks";
import {
  ListThumbnailsDto,
  UUID,
  buildUrl,
} from "../../data/service";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import Collapse from "@mui/material/Collapse";
import CardActions from "@mui/material/CardActions";
import Divider from "@mui/material/Divider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import { Skeleton, styled } from "@mui/material";
import styles from './UploadGallery.module.scss';
import classNames from "classnames";
import { AuthorizedImage } from "../../core-components/AuthorizedImage";
import { timestampToString } from "../../utils/utils";


export interface UploadGalleryProps {
  selectedId?: UUID | null;
  sampleContent: ListThumbnailsDto[] | undefined;
  thumbnailSize: number;
  onSelect?: (uploadId: UUID) => any;
}

export default function UploadGallery({
  sampleContent,
  thumbnailSize,
  selectedId,
  onSelect,
}: UploadGalleryProps) {
  return (
    <Stack spacing={2}>
      {
        sampleContent === undefined
        ? <GhostItem thumbnailSize={thumbnailSize}/>
        : (
          sampleContent.length
          ? sampleContent.map(upload => <UploadItem
              upload={upload}
              onSelect={onSelect}
              selected={upload._id === selectedId}
              key={upload._id}
            />)
          : <Alert severity="info">This sample doesn&apos;t contain micrographs yet.</Alert>
        )
      }
    </Stack>
  );
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

function UploadItem({
  upload,
  onSelect,
  selected,
} : {
  upload: ListThumbnailsDto;
  onSelect?: (uploadId: UUID, tab?: string) => any;
  selected?: boolean;
}) {
  const [token, ] = useToken();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleSelect = (uploadId: UUID, tab?: string) => onSelect && onSelect(uploadId, tab);

  const style = selected ? { background: 'rgba(25, 118, 210, 0.08)' } : {};

  return (
    <Card variant="outlined" sx={style}>
      <CardActionArea onClick={() => handleSelect(upload._id)}>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: 'secondary.main' }}>
              { upload.upload_num ?? '?' }
            </Avatar>
          }
          title={upload.uploaded_filename}
          subheader={`Uploaded: ${timestampToString(upload.created_at)} | Last modified: ${timestampToString(upload.updated_at)}`}
        />
      </CardActionArea>
      <CardContent>
        <Grid container justifyContent="flex-end" alignItems="center">
          <Grid item xs className={classNames(styles.ImageGridCell)} onClick={() => handleSelect(upload._id, 'micrograph')}>
            <AuthorizedImage token={token!} url={buildUrl(upload.corrected_b64_image)} alt={upload._id} />
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item xs className={classNames(styles.ImageGridCell)} onClick={() => handleSelect(upload._id, 'segmentation')}>
            {
              upload.segmented_b64_image
              ? <AuthorizedImage token={token!} url={buildUrl(upload.segmented_b64_image)} alt={upload._id} />
              : <Typography variant="caption">No segmented image</Typography>
            }
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item xs className={classNames(styles.ImageGridCell)}  onClick={() => handleSelect(upload._id, 'karyogram')}>
            {
              upload.karyogram_b64_image
              ? <AuthorizedImage token={token!} url={buildUrl(upload.karyogram_b64_image)} alt={upload._id} />
              : <Typography variant="caption">No karyogram</Typography>
            }
          </Grid>
        </Grid>
      </CardContent>
      <CardActions >
        <ExpandMore
          color="primary"
          size="large"
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>
            {upload.ISCN_code ?? '(No ISCN code)'}
          </Typography>
          <Typography paragraph>
            {upload.diagnosis_comment ?? '(No diagnosis comment)'}
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}

function GhostItem({ thumbnailSize }: { thumbnailSize: number }) {
  return (
    <Card variant="outlined">
      <CardHeader
        avatar={<CircularProgress />}
        title={<Skeleton variant="text" width={200}/>}
        subheader={<Skeleton variant="text" width={400}/>}
      />
      <CardContent>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs>
            <Skeleton variant="rectangular" width={thumbnailSize} height={thumbnailSize}/>
          </Grid>
          <Grid item xs>
            <Skeleton variant="rectangular" width={thumbnailSize} height={thumbnailSize}/>
          </Grid>
          <Grid item xs>
            <Skeleton variant="rectangular" width={thumbnailSize} height={thumbnailSize}/>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
