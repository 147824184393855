import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useState } from "react";
import Alert from "@mui/material/Alert";

export interface AuthorizedImageProps {
  token?: string;
  url: string;
  alt?: string;
  revoke?: boolean;
  onLoad?: () => void;
  [props: string]: any;
}

type AuthorizedImageStatus = 'loading' | 'success' | 'error';

export const AuthorizedImage = React.forwardRef(({
  token,
  url,
  alt,
  revoke = true,
  onLoad,
  ...props
}: AuthorizedImageProps, ref: any) => {
  const [status, setStatus] = useState<AuthorizedImageStatus>('loading');
  const [objUrl, setObjUrl] = useState<string | null>();

  useEffect(() => {
    (async () => {
      try {
        const resp = await fetch(url, {
          method: 'GET',
          ...(token && {headers: { 'Authorization': `Bearer ${token}`, }}),
        });
        const blob = await resp.blob();
        setObjUrl(URL.createObjectURL(blob));
        setStatus('success');
      } catch (e) {
        console.error(e);
        setStatus('error');
      }
    })();
  }, [url, token]);

  const handleLoad = () => {
    if (revoke && objUrl) {
      URL.revokeObjectURL(objUrl)
    }
    if (onLoad) {
      onLoad();
    }
  }

  if (status === 'loading') {
    return <CircularProgress />;
  }

  if (status === 'success' && objUrl) {
    return (
      <img
        ref={ref}
        src={objUrl}
        alt={alt}
        onLoad={handleLoad}
        {...props}
      ></img>
    );
  }

  return <Alert severity="error">Couldn&apos;t load image ({url})</Alert>;
});

export const AuthorizedSvgImage = ({
  token,
  url,
  alt,
  ...props
}: AuthorizedImageProps) => {
  const [status, setStatus] = useState<AuthorizedImageStatus>('loading');
  const [objUrl, setObjUrl] = useState<string | null>();

  useEffect(() => {
    (async () => {
      try {
        const resp = await fetch(url, {
          method: 'GET',
          headers: { 'Authorization': `Bearer ${token}`, },
        });
        const blob = await resp.blob();
        setObjUrl(URL.createObjectURL(blob));
        setStatus('success');
      } catch (e) {
        console.error(e);
        setStatus('error');
      }
    })();
  }, [url, token]);

  if (status === 'loading') {
    return <CircularProgress />;
  }

  if (status === 'success' && objUrl) {
    return (
      <image
        href={objUrl}
        onLoad={() => URL.revokeObjectURL(objUrl)}
        {...props}
      />
    );
  }

  return <Alert severity="error">Couldn&apos;t load image ({url})</Alert>;
}

// const imageEqual = (prev: AuthorizedImageProps, next: AuthorizedImageProps) => {
//   return prev.url === next.url;
// }

//export const MemoizedAuthorizedImage = React.memo(AuthorizedImage, imageEqual);
