import { useNavigate } from "react-router-dom";
import PatientList, { PatientListProps } from "../components/PatientList";
import Box from "@mui/material/Box";
import { PatientDto } from "../data/service";

export interface PatientsPageProps extends PatientListProps {}

export default function PatientsPage(props: PatientsPageProps) {
  const navigate = useNavigate();

  const handlePatient = (patient: PatientDto | null) => {
    if (patient) {
      navigate(`/karyo/patients/${patient!._id}`);
    }
  }

  return (
    <Box sx={{ width: '1200px' }}>
      <PatientList
        onSelect={handlePatient}
        {...props}
      />
    </Box>
  );
}
