import { SxProps } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import { useNavigate, useParams } from "react-router-dom";
import SampleList from "../components/SampleList";
import { useEffect, useState } from "react";
import { PatientDto, SampleDto, getPatient } from "../data/service";
import { useToken } from "../data/hooks";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import { CardContent, List, ListItem } from "@mui/material";
import { timestampToString } from "../utils/utils";

export interface PatientDetailsPageProps {
  onSelectSample?: (sample: SampleDto) => any;
  sx?: SxProps;
}

/**
 * * Detailed information of the patient
 * * Patient editing [TODO]
 * * List of samples
 */
export default function PatientDetailsPage(props: PatientDetailsPageProps) {
  const navigate = useNavigate();
  const [token, ] = useToken();
  const { patientId } = useParams();
  const [patient, setPatient] = useState<PatientDto>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    let ignore = false;
    async function get() {
      try {
        const p = await getPatient(token, patientId!);
        if (!ignore) {
          setPatient(p);
        }
      } catch (err) {
        setError(true);
      }
    }

    if (patientId) {
      get();
    }
    setLoading(false);
    return () => { ignore = true };
  }, [patientId, token]);

  const handleSelectedSample = (sample: SampleDto | null) => {
    if (sample) {
      navigate(`/karyo/samples/${sample!._id}`);
      props.onSelectSample && props.onSelectSample(sample);
    }
  }

  if (loading || !patient) {
    return <CircularProgress />;
  }
  if (error) {
    return <Alert severity="error">Could&apos;t load patient data.</Alert>;
  }
  return (
    <Stack sx={{ width: '1200px' }} direction="column" gap={2}>
      <Card sx={{ p: 2, pb: 0 }}>
        <CardHeader
          title={`Patient: ${patient.patient_name}`}
          subheader={patient._id}
        />
        <CardContent>
          <List >
            <ListItem>
              Identifier code: {patient.hospital_id}
            </ListItem>
            <ListItem>
              Created at: {timestampToString(patient.created_at)}
            </ListItem>
            <ListItem>
              Last modified: {timestampToString(patient.updated_at)}
            </ListItem>
          </List>
        </CardContent>
      </Card>
      <SampleList
        patient={patient}
        onSelect={handleSelectedSample}
        {...props}
      />
    </Stack>
  );
}
