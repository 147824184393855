import { useCallback, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useToken } from "../data/hooks";
import { DownloadImageDto, PredictionsDto, UploadDto, buildUrl, downloadImage, getLastAnnotations, getMicrographInfo } from "../data/service";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { SegmentationViewer } from "../components/SegmentationPlot";
import KaryogramEditor from "../components/KaryogramEditor";
import MicrographView from "../components/MicrographView";
import Card from "@mui/material/Card";
import Alert from "@mui/material/Alert";
import CardActionArea from "@mui/material/CardActionArea";
import SegmentationEditor from "../components/SegmentationEditor";
import { AuthorizedImage } from "../core-components";

type ValidTab = 'micrograph' | 'segmentation' | 'karyogram';

export default function UploadDetailsPage() {
  const { uploadId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const validTabs: ValidTab[] = ['micrograph', 'segmentation', 'karyogram'];
  const selectedTab = (searchParams.get('tab') || 'micrograph') as ValidTab;

  const tabValue = validTabs.includes(selectedTab) ? selectedTab : 'micrograph';

  const [info, setInfo] = useState<UploadDto>();
  const [micrographData, setMicrographData] = useState<DownloadImageDto>();
  const [karyogramImage, setKaryogramImage] = useState<string>();
  const [predictions, setPredictions] = useState<PredictionsDto>();
  const [hoveredChromosomeId, setHoveredChromosomeId] = useState<string>();

  const [token, ] = useToken();
  useEffect(() => {
    getMicrographInfo(token!, uploadId!).then(setInfo);
  }, [token, uploadId]);

  const getKaryogramImage = useCallback(async () => {
    if (info?.karyogram_filename) {
      try {
        const res = await downloadImage(token, {filetype: 'karyogram', upload_id: uploadId!});
        setKaryogramImage(buildUrl(res.b64_image));
      } catch (e) {
        // Not an error
      }
    }
  }, [token, uploadId, info]);

  useEffect(() => {
    (async () => {
      try {
        const res = await downloadImage(token, {filetype: 'corrected', upload_id: uploadId!});
        setMicrographData({ ...res, b64_image: buildUrl(res.b64_image) });
      } catch (e) {
        console.error(e);
      }
      try {
        const p = await getLastAnnotations(token, uploadId!);
        setPredictions(p);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [token, uploadId]);

  useEffect(() => {
    getKaryogramImage();
  }, [token, uploadId, predictions, getKaryogramImage]);

  const micrographSmallComponent = (
    <Card>
      <CardActionArea onClick={() => setSearchParams({tab: 'micrograph'})}>
        {
          micrographData
          ? <AuthorizedImage
              url={micrographData?.b64_image}
              token={token!}
              width={'100%'}
            />
          : <CircularProgress />
        }
      </CardActionArea>
    </Card>
  );

  const micrographLargeComponent = (
    <MicrographView
      imageUrl={micrographData?.b64_image}
      token={token!}
      imageWidth={micrographData?.width ?? 0}
      imageHeight={micrographData?.height ?? 0}
      props={{
        height: '100%',
        //width: '100%',
      }} />
  );

  const segmentationSmallComponent = (
    <Card>
      <CardActionArea onClick={() => setSearchParams({tab: 'segmentation'})}>
        {
          predictions
          ? <SegmentationViewer
              image={micrographData!.b64_image}
              predictions={predictions!}
              token={token!}
              transform={null}
              selectedChromo={hoveredChromosomeId}
            />
          : <CircularProgress />
        }
      </CardActionArea>
    </Card>
  );

  const segmentationLargeComponent = predictions ? <SegmentationEditor
    id="edit"
    uploadId={uploadId!}
    lastSnapshot={predictions}
    setLastSnapshot={setPredictions}
  /> : <CircularProgress />;

  const karyogramSmallComponent = (
    <Card>
      <CardActionArea onClick={() => setSearchParams({tab: 'karyogram'})}>
        {
          karyogramImage
          ? <AuthorizedImage
            url={karyogramImage!}
            token={token!}
            width={'100%'}
          />
          : <Alert severity="info">No up-to-date finalized karyogram.</Alert>
        }
      </CardActionArea>
    </Card>
  );

  const karyogramLargeComponent = (
    predictions
    ? <KaryogramEditor
        uploadId={uploadId!}
        lastSnapshot={predictions}
        setLastSnapshot={setPredictions}
        setHoveredChromosomeId={setHoveredChromosomeId}
        onFinalized={() => getKaryogramImage()}
        sx={{ alignContent: 'stretch' }}
      />
    : <CircularProgress />
  );

  const gridProps = {
    height: 'calc(100vh - 100px)',
    spacing: 2,
  };

  const micrographView = (
    <Grid container {...gridProps}>
      <Grid item xs={3}>
        <Stack spacing={2}>
          {segmentationSmallComponent}
          {karyogramSmallComponent}
        </Stack>
      </Grid>
      <Grid item xs={9}>
        {micrographLargeComponent}
      </Grid>
    </Grid>
  );

  const segmentationView = (
    <Grid container {...gridProps}>
      <Grid item xs={3}>
        <Stack spacing={2}>
          {micrographSmallComponent}
          {karyogramSmallComponent}
        </Stack>
      </Grid>
      <Grid item xs={9}>
        {segmentationLargeComponent}
      </Grid>
    </Grid>
  );

  const karyogramView = (
    <Grid container {...gridProps}>
      <Grid item xs={3}>
        <Stack spacing={2}>
          {segmentationSmallComponent}
          {micrographSmallComponent}
        </Stack>
      </Grid>
      <Grid item xs={9}>
        {karyogramLargeComponent}
      </Grid>
    </Grid>
  );

  switch (tabValue) {
    case 'karyogram': return karyogramView;
    case 'micrograph': return micrographView;
    case 'segmentation': return segmentationView;
  }
}
